exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mindmaps-jira-acknowledgements-js": () => import("./../../../src/pages/mindmaps-jira/acknowledgements.js" /* webpackChunkName: "component---src-pages-mindmaps-jira-acknowledgements-js" */),
  "component---src-pages-mindmaps-jira-documentation-js": () => import("./../../../src/pages/mindmaps-jira/documentation.js" /* webpackChunkName: "component---src-pages-mindmaps-jira-documentation-js" */),
  "component---src-pages-mindmaps-jira-index-js": () => import("./../../../src/pages/mindmaps-jira/index.js" /* webpackChunkName: "component---src-pages-mindmaps-jira-index-js" */),
  "component---src-pages-mindmaps-jira-sandboxdemo-js": () => import("./../../../src/pages/mindmaps-jira/sandboxdemo.js" /* webpackChunkName: "component---src-pages-mindmaps-jira-sandboxdemo-js" */),
  "component---src-pages-mindmaps-jira-sandboxdemo-print-jsx": () => import("./../../../src/pages/mindmaps-jira/sandboxdemo-print.jsx" /* webpackChunkName: "component---src-pages-mindmaps-jira-sandboxdemo-print-jsx" */),
  "component---src-pages-mindmaps-pro-confluence-acknowledgements-js": () => import("./../../../src/pages/mindmaps-pro-confluence/acknowledgements.js" /* webpackChunkName: "component---src-pages-mindmaps-pro-confluence-acknowledgements-js" */),
  "component---src-pages-mindmaps-pro-confluence-documentation-js": () => import("./../../../src/pages/mindmaps-pro-confluence/documentation.js" /* webpackChunkName: "component---src-pages-mindmaps-pro-confluence-documentation-js" */),
  "component---src-pages-mindmaps-pro-confluence-index-js": () => import("./../../../src/pages/mindmaps-pro-confluence/index.js" /* webpackChunkName: "component---src-pages-mindmaps-pro-confluence-index-js" */),
  "component---src-pages-mindmaps-pro-confluence-sandboxdemo-js": () => import("./../../../src/pages/mindmaps-pro-confluence/sandboxdemo.js" /* webpackChunkName: "component---src-pages-mindmaps-pro-confluence-sandboxdemo-js" */),
  "component---src-pages-move-organize-jira-acknowledgements-js": () => import("./../../../src/pages/move-organize-jira/acknowledgements.js" /* webpackChunkName: "component---src-pages-move-organize-jira-acknowledgements-js" */),
  "component---src-pages-move-organize-jira-documentation-js": () => import("./../../../src/pages/move-organize-jira/documentation.js" /* webpackChunkName: "component---src-pages-move-organize-jira-documentation-js" */),
  "component---src-pages-move-organize-jira-index-js": () => import("./../../../src/pages/move-organize-jira/index.js" /* webpackChunkName: "component---src-pages-move-organize-jira-index-js" */),
  "component---src-pages-move-organize-jira-sandboxdemo-issue-js": () => import("./../../../src/pages/move-organize-jira/sandboxdemo-issue.js" /* webpackChunkName: "component---src-pages-move-organize-jira-sandboxdemo-issue-js" */),
  "component---src-pages-move-organize-jira-sandboxdemo-js": () => import("./../../../src/pages/move-organize-jira/sandboxdemo.js" /* webpackChunkName: "component---src-pages-move-organize-jira-sandboxdemo-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-support-index-js": () => import("./../../../src/pages/support/index.js" /* webpackChunkName: "component---src-pages-support-index-js" */),
  "component---src-pages-support-mindmaps-jira-bug-js": () => import("./../../../src/pages/support/mindmaps-jira-bug.js" /* webpackChunkName: "component---src-pages-support-mindmaps-jira-bug-js" */),
  "component---src-pages-support-mindmaps-jira-feature-js": () => import("./../../../src/pages/support/mindmaps-jira-feature.js" /* webpackChunkName: "component---src-pages-support-mindmaps-jira-feature-js" */),
  "component---src-pages-support-move-organize-bug-js": () => import("./../../../src/pages/support/move-organize-bug.js" /* webpackChunkName: "component---src-pages-support-move-organize-bug-js" */),
  "component---src-pages-support-move-organize-feature-js": () => import("./../../../src/pages/support/move-organize-feature.js" /* webpackChunkName: "component---src-pages-support-move-organize-feature-js" */)
}

