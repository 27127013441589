module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Emergence Software","short_name":"Emergence","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"standalone","icons":[{"src":"favicon.ico","sizes":"64x64 32x32 24x24 16x16","type":"image/x-icon"},{"src":"logo192.png","type":"image/png","sizes":"192x192"},{"src":"logo512.png","type":"image/png","sizes":"512x512"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-9RSTEH0195"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":604800,"cookie_update":false},"pluginConfig":{"head":true,"respectDNT":true,"exclude":["/support/mindmaps-jira-bug","/support/mindmaps-jira-feature","/support/move-organize-bug","/support/move-organize-feature","/move-organize-jira/sandboxdemo-issue/**"],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
